/**
*

    Template Name: Landi - Landscape Gardening React Template
    Author: TwinkleTheme
    Support: https://docs.twinkletheme.com/support/
    Description: Landi - Landscape Gardening React Template
    Version: 1.0

*/


/*

    CSS INDEX
    ===================

    01. Default
    02. Preloader
    03. Common Classes
    04. Section Title
    05. Header
    06. Hero area
    07. Service
    08. About
    09. Fun Fact
    10. Project
    11. Why Choose Us
    12. Testimonial
    13. Book Appointment
    14. How It Works
    15. Blog
    16. Footer
    17. Breadcrumb
    18. CTA
    19. Widgets
    20. FAQ
    21. Service/Project Details
    22. Pricing
    23. Error 404
    24. Blog Details
    25. Newsletter
    26. Features
    27. Team
    28. Quote Form
    29. Contact


-----------------------------------------------------------------------------------*/


/*----------------------------------------*/


/**
*   01. Default
*/

@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap');

body {
    /* font-family: 'Roboto', sans-serif; */
    font-family: "Signika", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #686868;
    font-size: 16px;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* font-family: 'Lora', serif; */
    /* font-family: 'Roboto', sans-serif'Roboto', sans-serif; */
    color: #523906;
    margin-top: 0px;
    font-weight: 700;
    text-transform: normal;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #523906;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
    color: #523906;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 35px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

ul {
    margin: 0px;
    padding: 0px;
}

li {
    list-style: none;
}

p {
    font-size: 16px;
    font-weight: normal;
    line-height: 28px;
    color: #686868;
    /* margin-bottom: 15px; */
}

input,
select {
    transition: 0.3s;
}

hr {
    border-bottom: 1px solid #eceff8;
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
}

label {
    color: #7e7e7e;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}

.img,
img {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

a,
.button,
button {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
    text-decoration: none;
    outline: none;
}

a:focus {
    text-decoration: none;
}

a:hover {
    color: #ec6e39;
}

a,
button {
    color: inherit;
    outline: medium none;
    text-decoration: none;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
    outline: 0;
}

*::-moz-selection {
    background: #222;
    color: #ffffff;
    text-shadow: none;
}

::-moz-selection {
    background: #222;
    color: #ffffff;
    text-shadow: none;
}

::selection {
    background: #222;
    color: #ffffff;
    text-shadow: none;
}

*::-moz-placeholder {
    color: #523906;
    font-size: 16px;
    opacity: .60;
}

*::placeholder {
    color: #523906;
    font-size: 16px;
    opacity: .60;
}

[data-overlay="theme-1"]::before {
    /* background-color: #523906; */
    background-color: #304526;
}

[data-overlay="theme-2"]::before {
    background-color: #228B22;
}

[data-overlay="black"]::before {
    background-color: #000;
}

.scroll-to-top {
    /* bottom: 100px !important; */
    bottom: 20px !important;
    /* right: 175px !important; */
    right: 20px !important;
    width: 50px !important;
    height: 61px !important;
    line-height: 61px !important;
    font-size: 18px !important;
    text-align: center !important;
    border-radius: 20px 20px 0 0 !important;
    background-color: #228B22 !important;
    color: #fff !important;
}


/**
*   02. Preloader
*/

.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #228B22;
    z-index: 99;
}

.preloader .sk-plane,
.preloader .sk-chase,
.preloader .sk-bounce,
.preloader .sk-wave,
.preloader .sk-pulse,
.preloader .sk-flow,
.preloader .sk-swing,
.preloader .sk-circle,
.preloader .sk-circle-fade,
.preloader .sk-grid,
.preloader .sk-fold,
.preloader .sk-wander {
    position: absolute;
    left: 50%;
    top: 45%;
    margin: 0;
    margin-left: -20px;
    margin-top: -20px;
}

.sk-plane,
.sk-chase-dot:before,
.sk-bounce-dot,
.sk-wave-rect,
.sk-pulse,
.sk-flow-dot,
.sk-swing-dot,
.sk-circle-dot:before,
.sk-circle-fade-dot:before,
.sk-grid-cube,
.sk-fold-cube:before,
.sk-wander-cube {
    background-color: #523906;
}


/**
*   03. Common Classes
*/

.fix {
    overflow: hidden;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.clear {
    clear: both;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.text-right {
    text-align: right;
}

.height-592 {
    height: 592px;
}

.height-800 {
    height: 800px;
}

.height-680 {
    height: 680px;
}

.bg-property {
    background-color: #ddd;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* height: 85vh; */
}

@media only screen and (max-width: 568px) and (min-width:200px) {
    /* .bg-property {
        height: 200px;
    } */
    .block-desc{
        margin-top: -120px !important;
    }
}


.bg-hover-style-1 {
    position: relative;
}

.bg-hover-style-1:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #523906;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.single-project:hover .bg-hover-style-1:before,
.works-content-wrapper:hover .bg-hover-style-1:before,
.single-blog-wrapper:hover .bg-hover-style-1:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #523906;
    position: absolute;
    opacity: .75;
    visibility: visible;
    transition: .3s;
}

.white-bg {
    background: #ffffff;
}

.gray-bg {
    background: #f1f1f1;
}

.gray-bg-2 {
    background: #f9f9f9;
}

.theme-bg-1 {
    /* background: #523906; */
    background: #fc9604;
}

.theme-bg-2 {
    background: #3a2701;
}

.theme-bg-3 {
    background: #228B22;
}

.theme-bg-4 {
    background: #faf7e6;
}

.theme-bg-5 {
    background: #bed6c5;
}

.cb {
    color: black !important;
}

.cw {
    color: rgb(247, 247, 247);
}

.b-color-1 {
    border-color: #f2ecc2;
}

.b-color-2 {
    border-color: #523906;
}

.box-shadow-1 {
    box-shadow: 0px 3px 81px 0px rgba(6, 6, 6, 0.09);
}

.box-shadow-2 {
    box-shadow: 0px 3px 81px 0px rgb(6 6 6 / 9%);
}

.box-shadow-3 {
    box-shadow: 0px 3px 81px 0px rgba(0, 0, 0, 0.09);
}

.white-color {
    color: #ffffff;
}

.l-btn {
    background-color: #228B22;
    color: #fff;
    /* font-family: 'Roboto', sans-serif; */
    font-family: "Signika", sans-serif;
    letter-spacing: 0.6px;
    font-size: 18px;
    font-weight: 700;
    padding: 14px 18px;
    border-radius: 50px;
    line-height: 1;
    display: inline-block;
    text-transform: uppercase;
    border: none;
}

.l-btn:hover {
    /* background-color: #523906; */
    background-color: #fc9604;
    color: #fff;
}


/**
*   04. Section Title
*/

.section-title span {
    display: block;
    font-size: 18px;
    color: #228B22;
    line-height: 28px;
    margin-bottom: 30px;
}

.section-title h2 {
    font-size: 46px;
    line-height: 56px;
}


/**
*   05. Header
*/

.header-info ul li {
    color: #f1f1f1;
    margin-right: 40px;
    display: inline-block;
}

.header-info ul li svg {
    color: #228B22;
    margin-right: 10px;
    margin-top: -3px;
}

.header-social a {
    color: #f1f1f1;
    margin-left: 17px;
}

.header-info ul li a:hover,
.header-social a:hover {
    color: #228B22;
}



.header-call-btn {
    padding: 18px 0;
}

.main-menu ul li {
    display: inline-block;
    margin-left: 45px;
    position: relative;
}

.main-menu ul li a {
    color: #523906;
    font-size: 18px;
    font-weight: 600;
    display: block;
    padding: 32px 0 25px 0;
}

.main-menu ul li:hover>a,
.main-menu ul li .submenu li:hover>a {
    color: #228B22;
}

.main-menu ul li .submenu {
    position: absolute;
    background-color: #fff;
    width: 230px;
    padding: 15px 25px;
    border-top: 4px solid #228B22;
    top: 110%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    z-index: 3;
}

.main-menu ul li:hover>.submenu {
    top: 100%;
    opacity: 1;
    visibility: visible;
    transition: .3s;
}

.main-menu ul li .submenu li {
    display: block;
    margin-left: 0px;
    text-align: left;
    transition: .3s;
}

.main-menu ul li .submenu li:hover {
    padding-left: 10px;
    transition: .3s;
}

.main-menu ul li .submenu li a {
    display: block;
    color: #523906;
    padding: 8px 0;
}

.header-call-btn img {
    margin-right: 12px;
}

.header-call-btn a {
    font-size: 24px;
    font-weight: 700;
    line-height: 70px;
    color: #523906;
    font-family: 'Lora', serif;
}

.header-call-btn a:hover {
    color: #228B22;
}

/**
*   06. Hero area
*/


/*Style 1*/

.hero-wrapper h1 {
    font-size: 75px;
    font-weight: 700;
    line-height: 85px;
    color: #fff;
    margin-bottom: 40px;
}

.hero-wrapper p {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 30px;
}

.hero-popup-content img {
    width: 340px;
    border-radius: 350px 0px 350px 350px;
    /* border-radius: 13%; */
    border-width: 17px;
    border-style: solid;
    border-color: #228B22;
    /* border-color: #228B22; */
}

.hero-popup-content {
    position: relative;
    padding: 20px;
    margin-left: 200px;
}

.hero-popup-content:before {
    content: '';
    display: block;
    width: 380px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 350px 0 350px 350px;
    /* border-radius: 13%; */
    border-width: 18px;
    border-style: solid;
    border-color: #fff;
}

button.popup-video {
    display: block;
    background-color: #fbfbfa;
    /* color: #228B22; */
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 24px;
    border-radius: 50px;
    position: absolute;
    top: 40%;
    left: 44%;
    border: none;
}

.pulse-effect {
    animation: pulse-effect 1s infinite;
}

@keyframes pulse-effect {
    0% {
        box-shadow: 0 0 0 0px #fff;
    }

    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}


/*Style 2*/

.hero-slider-1 .single-slider-style-1 {
    position: relative;
    z-index: 2;
}

.hero-slider-1 .single-slider-style-1:before {
    content: '';
    display: block;
    background-image: linear-gradient(0deg, rgb(82, 57, 6) 0%, rgba(82, 57, 6, 0.5) 47%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%);
    background-image: -moz-linear-gradient(0deg, rgb(82, 57, 6) 0%, rgba(82, 57, 6, 0.5) 47%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%);
    background-image: -webkit-linear-gradient(0deg, rgb(82, 57, 6) 0%, rgba(82, 57, 6, 0.5) 47%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%);
    background-image: -ms-linear-gradient(0deg, rgb(82, 57, 6) 0%, rgba(82, 57, 6, 0.5) 47%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%, rgba(82, 57, 6, 0) 100%);
    position: absolute;
    left: 0px;
    top: 0;
    width: 1920px;
    height: 100%;
    z-index: -1;
}

.hero-slider-1 .slide-content-wrapper-1 h1 {
    font-size: 75px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 35px;
}

.hero-slider-1 .slide-content-wrapper-1 p {
    color: #fff;
    font-size: 20px;
    margin-bottom: 35px;
}

.hero-slider-1 ul.slick-dots li {
    display: inline-block;
    margin-left: 10px;
}

.hero-slider-1 ul.slick-dots {
    position: absolute;
    bottom: 10%;
    right: 18%;
}

.hero-slider-1 ul.slick-dots li button {
    background: transparent;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Lora', serif;
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
}

.hero-slider-1 ul.slick-dots li.slick-active button {
    background-color: #fff;
    border: 2px solid #fff;
    color: #523906;
}

.hero-slider-1 .slick-next {
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 30px;
    position: absolute;
    top: 50%;
    right: 17%;
    z-index: 1;
}

.hero-slider-1 .slick-prev {
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 12%;
    z-index: 1;
}


/*Style 3*/

.hero-slider-2 .slide-content-wrapper-2 h1 {
    font-size: 56px;
    line-height: 66px;
    color: #fff;
    margin-bottom: 40px;
}

.hero-slider-2 .slide-content-wrapper-2 p {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 35px;
}

.hero-slider-box {
    z-index: 2333;
    margin-top: -15px;
    margin-bottom: -15px;
}

.slick-active.slick-current .single-slider-box {
    background-color: #228B22;
}

.hero-slider-box .slick-next,
.hero-slider-box .slick-prev {
    position: absolute;
    background-color: #fff;
    color: #523906;
    font-size: 18px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border: none;
}

.hero-slider-box .slick-next {
    top: 50px;
    right: 0;
    border-radius: 50px 50px 0px 50px;
}

.hero-slider-box .slick-prev {
    top: 50px;
    left: 88%;
    border-radius: 0px 50px 50px 50px;
}

.hero-slider-box .slick-prev:hover,
.hero-slider-box .slick-next:hover {
    background-color: #228B22;
    color: #fff;
}


/**
*   07. Service
*/

.single-service {
    background-color: #fff;
    padding: 25px 25px 30px 25px;
    margin-bottom: 25px;
    transition: .3s;
}

.single-service:hover {
    background-color: #228B22;
    color: #fff;
    transition: .3s;
}

.sub-category {
    background-color: #fff;
    padding: 0px;
    margin-bottom: 25px;
    transition: .3s;
    min-height: 398px;
}

.sub-category:hover {
    background-color: #228B22;
    color: #fff;
    transition: .3s;
}

.single-service:hover h3,
.single-service:hover p,
.single-service:hover a {
    color: #fff;
}

.sub-category:hover h3,
.sub-category:hover p,
.sub-category:hover a {
    color: #fff;
}

.service-icon {
    background-color: #faf7e6;
    width: 80px;
    height: 80px;
    transform: rotate(45deg);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-left: 21px;
    margin: 30px 0 35px 21px;
}

.service-icon img,
.service-icon i {
    transform: rotate(-45deg);
}

.service-icon i {
    font-size: 40px;
}

.single-service h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 25px;
}

.single-service p {
    margin-bottom: 20px;
}

.single-service a {
    font-size: 16px;
    line-height: 30px;
    color: #228B22;
}

.single-service a svg {
    margin-left: 7px;
    margin-top: -1px;
}

.sub-category p {
    margin-bottom: 20px;
}

.sub-category a {
    font-size: 16px;
    line-height: 30px;
    color: #228B22;
}

.sub-category a svg {
    margin-left: 7px;
    margin-top: -1px;
}

.single-service-large {
    background-color: #228B22;
    padding: 46px 30px;
}

.single-service-large h3 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 30px;
    color: #fff;
}

.single-service-large h3 span {
    font-weight: 700;
}

.single-service-large p {
    color: #fff;
    margin-bottom: 50px;
}

.single-service-large .l-btn {
    background-color: #523906;
}

.service-readmore-icon {
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.single-service:hover .service-readmore-icon {
    opacity: 1;
    visibility: visible;
    transition: .3s;
}


/**
*   08. About
*/


/*Style 1*/

.about-wrapper-style-1 {
    position: relative;
}

.about-secondary {
    position: absolute;
    top: 250px;
    right: 0;
}

.service-bullet-list ul li {
    margin-bottom: 15px;
}

.service-bullet-list ul li svg {
    margin-right: 10px;
    color: #000000;
}


/* Style 2 */

.about-img-style-1 {
    position: relative;
    z-index: 2;
}

.about-img-style-1 img {
    width: 100%;
    padding-right: 110px;
}

.about-img-style-1:before {
    content: '';
    display: block;
    position: absolute;
    width: 80%;
    height: 90%;
    background-color: #228B22;
    z-index: -1;
    top: 80px;
    right: 80px;
}

.about-award {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-35%, -50%);
    background: #c8b224;
    width: 220px;
    height: 220px;
    text-align: center;
    border-radius: 100%;
    opacity: .85;
}

.about-award h3 {
    font-size: 24px;
    color: #fff;
    padding: 70px 0;
    line-height: 34px;
}


/* Style 3*/

.about-img-style-2 {
    position: relative;
    z-index: 2;
}

.about-img-style-2:before {
    content: '';
    display: block;
    width: 100%;
    height: 90%;
    background-color: #228B22;
    position: absolute;
    top: 10%;
    left: -40px;
    z-index: -1;
}

.about-experience {
    position: absolute;
    left: -160px;
    bottom: 0;
}

.about-experience h2 {
    font-size: 46px;
}

.about-experience span {
    font-size: 18px;
    color: #523906;
}

.about-small-img {
    position: absolute;
    top: 20%;
    left: -160px;
    border-width: 12px 12px 12px 0px;
    border-style: solid;
    border-color: #fff;
}

.about-author-avatar-wrapper img {
    border-radius: 100px;
    width: 70px;
    height: 70px;
    float: left;
    margin-right: 20px;
}

.about-author-avatar-content {
    float: left;
    overflow: hidden;
}

.about-author-avatar-content h5 {
    margin-bottom: 10px;
}

.about-author-avatar-content span {
    color: #228B22;
}


/* Style 4*/

.about-block h2 {
    font-size: 46px;
    line-height: 56px;
    margin-bottom: 30px;
}

.experience-area {
    position: relative;
}

.single-experience {
    background-color: #f9f9f9;
    padding: 25px;
    margin-bottom: 25px;
}

.single-experience h4 {
    font-size: 24px;
    padding: 15px 30px;
    background-color: #523906;
    color: #fff;
    display: inline-block;
    margin-bottom: 16px;
}

.single-experience p {
    margin-bottom: 0;
}

.single-experience:hover {
    background-color: #228B22;
    transition: .3s;
}

.single-experience:hover p {
    color: #fff;
}

.experience-img {
    position: relative;
}

.experience-img img {
    width: 100%;
}

.experience-small-block {
    position: absolute;
    left: 30px;
    bottom: 30px;
    background-color: #cdae20;
    width: 200px;
    height: 140px;
    padding: 20px;
}

.experience-small-block h5 {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin-top: 5px;
    margin-bottom: 0;
}


/**
*   09. Fun Fact
*/

.single-counter h2 {
    color: #fff;
    font-size: 46px;
    margin-bottom: 20px;
}

.single-counter p {
    color: #fff;
    font-size: 20px;
}


/**
*   10. Project
*/

.single-project {
    transition: .3s;
}

.single-project-img {
    height: 285px;
    position: relative;
    transition: .3s;
}

.single-project-img-icon {
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0;
    visibility: hidden;
}

.single-project:hover .single-project-img-icon {
    opacity: 1;
    visibility: visible;
}

.single-project-img-icon a svg {
    font-size: 14px;
    color: #523906;
    margin: 13px 0;
}

.single-project-content h3 {
    margin-bottom: 20px;
}

.single-project-content p {
    margin-bottom: 0px;
}

.single-project-content {
    background-color: #faf7e6;
    padding: 50px 30px 35px 30px;
    border-bottom: 3px solid;
    border-color: #523906;
}

.single-project:hover .single-project-content {
    background-color: #228B22;
    transition: .3s;
    border-color: #228B22;
}

.single-project:hover .single-project-content h3,
.single-project:hover .single-project-content p {
    color: #fff;
}


/*Project Masonry Style*/

.portfolio-menu {
    display: inline-block;
    background-color: #228B22;
    padding: 0 40px;
    height: 72px;
}

.portfolio-menu button {
    border: none;
    background-color: transparent;
    color: #faf7e6;
    font-size: 18px;
    font-weight: 700;
    font-family: 'lora', serif;
    padding: 20px 15px;
}

.portfolio-menu button {
    border-bottom: 5px solid #228B22;
}

.portfolio-menu button.active {
    border-bottom: 5px solid #523906;
}

.single-project-isotope:before,
.single-project-isotope-style-2:before {
    content: '';
    display: block;
    width: 100%;
    height: 0%;
    /* background-color: #5a4212; */
    background-color: #125a56e0;
    opacity: .95;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    transition: all 0.31s ease-out 0s;
}

.single-project-isotope {
    position: relative;
    overflow: hidden;
    height: 350px;
}

.single-project-isotope-style-2 {
    position: relative;
    overflow: hidden;
    height: 250px;
}

.single-project-isotope:hover:before,
.single-project-isotope-style-2:hover:before {
    height: 100%;
}

.single-project-isotope img,
.single-project-isotope-style-2 img {
    width: 100%;
    height: 350px;
}

.single-project-isotope:hover .project-isotope-content-wrapper {
    bottom: 12%;
    transform: translateY(-12%);
}

.project-isotope-content-wrapper {
    position: absolute;
    bottom: -190px;
    left: 0;
    z-index: 2;
    text-align: center;
    padding: 0 40px;
    transition: all 0.3s ease-out 0s;
}

.project-isotope-content-wrapper h3 {
    font-size: 24px;
    color: #fff;
}

.project-isotope-content-wrapper p {
    color: #fff;
}

.project-isotope-content-wrapper a {
    color: #228B22;
}

.project-isotope-content-wrapper a svg {
    margin-left: 7px;
}

.portfolio-heading-content-2 {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding: 0;
    transition: all 0.3s ease-out 0s;
    opacity: 0;
    visibility: hidden;
}

.single-project-isotope-style-2:hover .portfolio-heading-content-2 {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding: 0;
    transition: all 0.3s ease-out 0s;
    opacity: 1;
    visibility: visible;
}

.portfolio-heading-content-2 h3 {
    background-color: #228B22;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
    margin-bottom: 0;
}

.portfolio-content-2 {
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -40%);
}

.portfolio-content-2 a,
.portfolio-content-2 button {
    display: inline-block;
    background-color: #228B22;
    color: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50px;
    margin: 0 2px;
    opacity: 0;
    visibility: hidden;
    border: none;
}

.single-project-isotope-style-2:hover .portfolio-content-2 a,
.single-project-isotope-style-2:hover .portfolio-content-2 button {
    display: inline-block;
    background-color: #228B22;
    color: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50px;
    margin: 0 2px;
    opacity: 1;
    visibility: visible;
}


/**
*   11. Why Choose Us
*/

.single-choose-us {
    background-color: #fff;
    padding-top: 53px;
    padding-bottom: 53px;
    box-shadow: 0px 3px 81px 0px rgba(6, 6, 6, 0.09);
}

.single-choose-us-content {
    padding: 0 25px 40px 25px;
}

.single-choose-us-icon {
    padding: 45px 25px 0 25px;
}

.single-choose-us-content h3 {
    font-size: 20px;
    margin-bottom: 25px;
}


/**
*   12. Testimonial
*/

.single-testimonial {
    background-color: #fff;
    padding: 36px 56px;
    margin-bottom: 20px;
    text-align: center;
}

.single-testimonial img {
    width: 110px;
    border-radius: 100%;
    margin-bottom: 30px !important;
    margin: 0 auto;
}

.single-testimonial h3 {
    font-size: 20px;
    margin-bottom: 16px;
}

.single-testimonial span {
    display: block;
    color: #228B22;
    margin-bottom: 25px;
    font-size: 18px;
}

.single-testimonial p {
    margin-bottom: 18px;
}

.star-rating svg {
    color: #228B22;
    margin: 0 2px;
    font-size: 18px;
}

.testimonial-style-1 ul.slick-dots {
    text-align: center;
    margin-top: 50px;
}

.testimonial-style-1 ul.slick-dots button {
    width: 27px;
}

.testimonial-style-1 ul.slick-dots li button {
    background-color: #523906;
    border: none;
    width: 23px;
    height: 7px;
    border-radius: 50px;
}

.testimonial-style-1 ul.slick-dots li {
    list-style: none;
    display: inline-block;
    margin-right: 10px;
    font-size: 0;
}

.testimonial-style-1 ul.slick-dots li.slick-active button {
    width: 36px;
    height: 7px;
    background-color: #ccb20c;
}

.testimonial-style-1 .slick-slide {
    margin: 0 10px;
    width: 412px !important;
}

.testimonial-img img {
    border-radius: 10px 350px 350px 350px;
    border-width: 10px;
    border-style: solid;
    border-color: #228B22;
}

.testimonial-style-2-wrapper {
    position: relative;
}

.testimonial-style-2 {
    background-color: #fff;
    margin-left: -240px;
    margin-bottom: 70px;
    padding: 60px 100px 60px 100px;
    border-radius: 150px 150px 10px 150px;
    box-shadow: 0px 3px 81px 0px rgb(0 0 0 / 9%);
    position: relative;
}

.single-testimonial-style-2 p {
    color: #523906;
    font-size: 18px;
    margin-bottom: 30px;
}

.single-testimonial-style-2 h4 {
    font-size: 20px;
    margin-bottom: 15px;
}

.single-testimonial-style-2 span {
    font-size: 18px;
    color: #228B22;
}

.testimonial-style-2 ul.slick-dots {
    position: absolute;
    bottom: 67px;
    right: 100px;
}

.testimonial-style-2 ul.slick-dots li {
    display: inline-block;
    margin-left: 10px;
}

.testimonial-style-2 ul.slick-dots li button {
    font-size: 0;
    background-color: #f0e8b6;
    border: none;
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.testimonial-style-2 ul.slick-dots li.slick-active button {
    background-color: #228B22;
}

.testimonial-quote-icon {
    background-color: #228B22;
    position: absolute;
    top: -140px;
    left: -110px;
    width: 85px;
    height: 170px;
    text-align: center;
    border-radius: 50px 50px 50px 0px;
}

.testimonial-quote-icon svg {
    font-size: 36px;
    color: #fff;
    line-height: 170px;
    margin: 67px 0;
}


/**
*   13. Book Appointment
*/

.book-appointment {
    position: relative;
}

.book-appointment-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
}

.book-quote-widget input[type="text"],
.book-quote-widget input[type="email"] {
    margin-right: 20px;
}

.book-quote-widget input[type="text"],
.book-quote-widget input[type="email"],
.book-quote-widget textarea {
    background-color: #f1f1f1;
    border: none;
    padding: 20px 18px;
    width: 100%;
    margin-bottom: 18px;
}

a.l-btn.quote-btn {
    width: 100%;
    border-radius: 0;
    padding: 20px 17px;
}

a.l-btn.quote-btn svg {
    float: right;
}


/**
*   14. How It Works
*/

.how-it-works-icon {
    display: inline-block;
    background-color: #228B22;
    width: 130px;
    height: 130px;
    line-height: 130px;
    border-radius: 100px;
    text-align: center;
}

.works-icon {
    border-top: 4px;
    border-right: 1px;
    border-bottom: 3px;
    border-left: 6px;
    border-style: solid;
    border-color: #228B22;
    display: inline-block;
    padding: 5px;
    border-radius: 100px;
}

.works-icon img {
    background-color: #228B22;
    padding: 30px;
    border-radius: 100px;
    width: 130px;
    height: 130px;
}

.works-icon:before {
    content: '';
    display: block;
}

.works-menu .nav-item {
    display: inline-block;
    margin: 0 60px;
}

.works-menu .nav {
    display: inline-block;
}

.works-menu .nav-pills .nav-link {
    padding: 0;
}

.works-menu .nav-pills .nav-link.active {
    background-color: transparent;
}

.works-menu a {
    border: none;
    background-color: transparent;
}

.works-content-wrapper h3 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 30px;
}

.works-menu .active .works-icon {
    border-color: #523906;
}

.works-menu .active .works-icon img {
    background-color: #523906;
}

.works-img img {
    width: 100%;
}

.works-link {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.works-content-wrapper:hover .works-link {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    transition: .3s;
}


/**
*   15. Blog
*/

.single-blog-wrapper {
    background-color: #fff;
    transition: .3s;
}

.blog-details img,
.blog-img img {
    width: 100%;
}

.blog-img a {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.single-blog-wrapper:hover .blog-img a {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    transition: .3s;
}

.blog-content h4 a {
    display: block;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 30px;
}

.blog-content h2 a {
    display: block;
    margin-bottom: 40px;
}

ul.cat-list li {
    display: inline-block;
    margin-right: 17px;
}

ul.cat-list li a {
    color: #523906;
}

ul.cat-list li.parent-cat {
    position: relative;
    padding-left: 20px;
}

ul.cat-list li.parent-cat a {
    color: #228B22;
}

ul.cat-list li.parent-cat:before {
    content: '';
    display: block;
    width: 4px;
    height: 20px;
    border-radius: 50px;
    background-color: #228B22;
    position: absolute;
    top: 0;
    left: 0;
}

ul.post-meta li {
    display: inline-block;
}

ul.post-meta li a {
    color: #686868;
}

ul.post-meta li:after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background-color: #523906;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: 7px;
}

ul.post-meta li:last-child:after {
    display: none;
}

ul.post-meta li {
    position: relative;
    padding-right: 22px;
}

a.l-read-more {
    color: #228B22;
}

a.l-read-more svg {
    margin-left: 5px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.single-blog-wrapper:hover a.l-read-more svg {
    opacity: 1;
    visibility: visible;
    transition: .3s;
}

.landi-pagination ul li {
    display: inline-block;
}

.landi-pagination ul li {
    margin: 0 3px;
}

.landi-pagination ul li a {
    display: block;
    background-color: #228B22;
    color: #fff;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    font-family: 'lora', serif;
}

.landi-pagination ul li a:hover {
    background-color: #523906;
    color: #fff;
}

.landi-pagination ul li span.current {
    display: block;
    background-color: #523906;
    color: #fff;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    font-family: 'lora', serif;
}


/**
*   16. Footer
*/

.footer-title {
    margin-bottom: 50px;
}

.footer-widget-1 {
    padding-right: 130px;
}

.footer-widget-4 {
    padding-left: 130px;
}

.footer-title h4 {
    font-size: 24px;
    color: #fff;
}

.footer-text p {
    color: #eeeeee;
}

.footer-info a {
    display: block;
    font-size: 20px;
    color: #eeeeee;
    margin-bottom: 28px;
}

.footer-info a svg {
    color: #228B22;
    margin-right: 10px;
}

.footer-menu ul li {
    margin-bottom: 20px;
}

.footer-menu ul li a {
    display: block;
    color: #eeeeee;
}

.footer-menu ul li a:hover {
    color: #228B22;
}

.footer-social h5 {
    display: inline-block;
    color: #228B22;
    margin-right: 15px;
    font-size: 20px;
}

.footer-social ul,
.footer-social ul li {
    display: inline-block;
}

.footer-social ul li a {
    color: #eeeeee;
    margin: 0 5px;
}

.footer-social ul li a:hover,
.footer-contact-info ul li a:hover {
    color: #228B22;
}

.footer-newsletter {
    position: relative;
}

.footer-newsletter input {
    width: 100%;
    height: 64px;
    padding: 0 30px;
    border-radius: 50px;
    border: none;
}

.footer-newsletter button {
    width: 64px;
    height: 64px;
    background-color: #228B22;
    color: #fff;
    border: none;
    border-radius: 100px;
    position: absolute;
    top: 0;
    right: 0;
}

.footer-contact-info ul li {
    margin-bottom: 20px;
}

.footer-contact-info ul li svg {
    float: left;
    color: #228B22;
    margin-top: 7px;
    margin-right: 13px;
}

.footer-contact-info ul li a,
.footer-contact-info ul li span {
    display: block;
    overflow: hidden;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
}

.copyright p {
    color: #eeeeee;
    margin-bottom: 0;
}

.copyright p a {
    color: #eeeeee;
}

.copyright p a:hover {
    color: #228B22;
}

.footer-bottom .footer-menu li {
    display: inline-block;
    padding-left: 25px;
    color: #eeeeee;
    margin-bottom: 0;
}


/**
*   17. Breadcrumb
*/

.breadcrumb-content-wrapper h2 {
    color: #fff;
    font-size: 46px;
    line-height: 60px;
    margin-bottom: 28px;
}

.breadcrumb-content-wrapper p {
    color: #fff;
    font-size: 20px;
}

.breadcrumb-content-wrapper ul.post-meta li:after {
    background-color: #228B22;
}

.breadcrumb-content-wrapper ul li,
.breadcrumb-content-wrapper ul li a {
    color: #228B22;
}

.breadcrumb-navigation {
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
}

.breadcrumb-navigation ul li {
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

.breadcrumb-navigation ul li {
    display: inline-block;
    color: #fff;
}

.breadcrumb-navigation ul li:hover a {
    color: #228B22;
}

.breadcrumb-navigation ul li.active {
    color: #228B22;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: '-';
    color: #fff;
    padding: 0 10px;
}


/**
*   18. CTA
*/

.cta-style-1-wrapper span {
    display: block;
    font-size: 18px;
    color: #228B22;
    margin-bottom: 25px;
}

.cta-style-1-wrapper h2 {
    font-size: 36px;
    color: #fff;
    margin-bottom: 30px;
    line-height: 46px;
}

.simple-cta h3 {
    font-size: 24px;
    margin-bottom: 0;
}

.cta-style-1-wrapper p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 38px;
}

.cta-style-1-wrapper .l-btn:hover {
    background-color: #228B22;
}

.cta-style-2-wrapper h2 {
    color: #fff;
    font-size: 30px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 0;
}

.cta-style-2-wrapper h2 span a {
    color: #fff;
}

.cta-style-2-wrapper h2 span {
    font-weight: 700;
}

.cta-style-3 {
    margin-top: -100px;
}

.cta-style-3-wrapper {
    position: relative;
    z-index: 1;
}

.cta-style-3-wrapper:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 12px;
    left: 12px;
    border: 2px solid #e5d77e;
    z-index: -1;
}

.cta-style-3-wrapper h3 {
    line-height: 38px;
    margin-bottom: 0;
}

.cta-shape {
    position: absolute;
    bottom: 30%;
    right: 32%;
    transform: rotate(330deg);
}


/**
*   19. Widgets
*/

.widget {
    padding: 35px 20px;
    position: relative;
    background-color: #f1f1f1;
}

.widget:before {
    content: '';
    display: block;
    width: 320px;
    height: 4px;
    background-color: #228B22;
    position: absolute;
    top: 0;
    left: 0;
}

.widget.contact-widget:before {
    display: none;
}

.widget-title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
}

.contact-widget h3 {
    color: #fff;
}

.service-list-widget ul li a {
    display: block;
    color: #523906;
    background-color: #fff;
    padding: 18px;
    margin: 11px 0;
    font-size: 18px;
    font-weight: 700;
    font-family: 'lora', serif;
    line-height: 30px;
}

.service-list-widget ul li a svg {
    float: right;
    margin: 7px 0;
}

.service-list-widget ul li a:hover {
    color: #fff;
    background-color: #228B22;
}

.contact-widget {
    background-color: #523906;
    padding: 40px 40px 30px 30px;
}

.contact-widget span {
    display: block;
    font-size: 20px;
    color: #fff;
    margin-bottom: 25px;
}

.contact-widget p {
    color: #fff;
    margin-bottom: 35px;
}

.contact-widget h3 a {
    display: inline-block;
    color: #fff;
    margin-top: 35px;
}

a.l-contact-btn {
    display: inline-block;
    background-color: #228B22;
    color: #fff;
    padding-left: 80px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    width: 209px;
    height: 60px;
    line-height: 60px;
}

a.l-contact-btn span {
    background-color: #fff;
    border-radius: 50px;
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

a.l-contact-btn span svg {
    color: #523906;
}

.quote-widget input {
    width: 100%;
    height: 60px;
    padding: 0 22px;
    font-size: 16px;
    border: none;
    margin: 7px 0;
    background-color: #fff;
}

.quote-widget textarea {
    width: 100%;
    border: none;
    padding: 15px 22px;
    font-size: 16px;
    margin: 7px 0;
    background-color: #fff;
}

.quote-widget button {
    background-color: #228B22;
    color: #fff;
    padding: 15px 20px;
    display: block;
    border: none;
    width: 100%;
    text-align: left;
    line-height: 30px;
}

.quote-widget button:hover {
    background-color: #523906;
}

.quote-widget svg {
    float: right;
    margin: 7px;
}

.search-widget input {
    height: 66px;
    width: 100%;
    border: 2px solid #228B22;
    padding: 0 20px;
    color: #523906;
}

.search-widget button {
    position: absolute;
    right: 20px;
    width: 66px;
    height: 66px;
    line-height: 66px;
    border: none;
    background-color: #228B22;
    color: #fff;
    font-size: 20px;
    text-align: center;
}

.category-widget ul li {
    display: block;
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: 700;
    font-family: 'lora', serif;
    color: #523906;
}

.category-widget ul li:last-child {
    margin-bottom: 0;
}

.category-widget ul li a {
    display: inline-block;
}

.category-widget ul li:hover,
.category-widget ul li:hover a {
    color: #228B22;
}

.category-widget ul li span {
    float: right;
}

.single-latest-post-widget {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #c9c3b6;
}

.single-latest-post-widget:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
}

.single-latest-post-widget h5 a {
    display: block;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 25px;
}

.single-latest-post-widget span {
    display: block;
    color: #228B22;
}

.single-latest-post-widget span:last-child {
    border: none;
}

.tag-cloud-widget .l-btn {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    padding: 13px 20px;
    margin-right: 5px;
    margin-bottom: 10px;
    text-transform: capitalize;
    background-color: #fff;
    color: #523906;
}

.tag-cloud-widget .l-btn:hover {
    background-color: #228B22;
    color: #fff;
}


/**
*   20. FAQ
*/

.faq .accordion {
    border: none;
}

.faq .accordion__button:before {
    float: right;
    margin: 10px 0;
}

.faq .accordion__button {
    color: #523906;
    font-size: 20px;
    font-weight: 600;
    font-family: 'lora', serif;
    background-color: transparent;
    outline: none;
    box-shadow: none;
}

.faq .accordion__button:hover {
    background-color: none;
}

.faq .accordion__panel {
    border-top: 1px solid #e5e1da;
}

.faq .accordion-button:not(.collapsed)::after {
    transform: var(--bs-accordion-btn-icon-transform);
}

.faq h2 {
    margin-bottom: 0;
}

.accordion__item {
    border: none;
    background-color: #fff;
    color: #686868;
    margin-bottom: 10px;
    padding: 0 20px;
    box-shadow: 0px 3px 81px 0px rgb(6 6 6 / 9%);
}

.faq .accordion-button:focus {
    outline: none;
}


/**
*   21. Service/Project Details
*/

.service-details-wrapper h2 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 33px;
}

.accordion-item h2 {
    margin-bottom: 0;
    line-height: 30px;
}

.service-details-wrapper img,
.project-details-wrapper img {
    width: 100%;
}

.single-project-info {
    padding: 35px;
    text-align: center;
}

.single-project-info h6 {
    color: #fff;
}

.single-project-info span {
    display: block;
    color: #fff;
    margin-bottom: 15px;
}


/**
*   22. Pricing
*/

.single-pricing-box h3 {
    color: #fff;
    font-size: 46px;
    line-height: 70px;
}

.single-pricing-box h4 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 25px;
}

.single-pricing-box a {
    color: #228B22;
}

.single-pricing-box a svg {
    margin-left: 7px;
}

.single-pricing-box ul li {
    margin: 16px 0;
}

.single-pricing-box ul li svg {
    color: #228B22;
    margin-right: 10px;
}


/**
*   23. Error 404
*/

.error-404-wrapper h2 {
    font-size: 150px;
    color: #228B22;
}

.error-404-wrapper h3 {
    font-size: 46px;
    margin-bottom: 30px;
}

.error-404-wrapper p {
    margin-bottom: 40px;
}


/**
*   24. Blog Details
*/

.quote-block {
    margin-top: 50px;
    margin-bottom: 80px;
}

.quote-icon {
    width: 48px;
    height: 48px;
    background-color: #fff;
    color: #228B22;
    border-radius: 50px;
    text-align: center;
    font-size: 20px;
    line-height: 48px;
    display: inline-block;
    margin-bottom: 40px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.quote-block p {
    font-size: 20px;
    font-weight: 600;
    font-family: 'lora', serif;
    line-height: 30px;
}

.post-tags h5,
.post-share h5 {
    margin-bottom: 16px;
}

.post-tags ul li {
    display: inline-block;
}

.post-tags ul li a {
    display: inline-block;
    background-color: #f1f1f1;
    color: #523906;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;
    margin-bottom: 8px;
}

.post-share ul li {
    display: inline-block;
    width: 35px;
    height: 35px;
    background-color: #f1f1f1;
    line-height: 35px;
    text-align: center;
    border-radius: 50px;
    font-size: 14px;
}

.post-share ul li a {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #228B22;
    color: #fff;
    border-radius: 50px;
    line-height: 33px;
}

.post-tags ul li a:hover,
.post-share ul li a:hover {
    background-color: #523906;
    color: #fff;
}

.post-prev-btn:hover,
.post-next-btn:hover {
    background-color: #228B22;
    color: #fff;
    padding: 22px 36px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'lora', serif;
    border-radius: 50px;
    text-transform: uppercase;
}

.post-prev-btn,
.post-next-btn {
    color: #523906;
    padding: 22px 36px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'lora', serif;
    border-radius: 50px;
    text-transform: uppercase;
}

.post-prev-btn i {
    margin-right: 10px;
}

.post-next-btn i {
    margin-left: 10px;
}

.post-author-avatar {
    border-left: 3px solid #228B22;
}

.post-author-avatar img {
    border-radius: 5px;
}

.post-author-bio h5 a {
    font-size: 20px;
}

.post-author-bio h5 a:hover,
.post-author-bio a:hover {
    color: #228B22;
}

.post-author-bio a {
    color: #523906;
    font-size: 14px;
    margin-right: 10px;
}

.comment-body {
    background-color: #fff;
    padding: 27px;
    margin-bottom: 35px;
    box-shadow: 0px 3px 81px 0px rgb(0 0 0 / 9%);
}

.comments-area h2,
.comments-form h2 {
    font-size: 30px;
    margin-bottom: 35px;
}

.comment-avatar {
    float: left;
    margin-right: 30px;
}

.comment-content {
    overflow: hidden;
}

.comment-content h5 {
    display: inline-block;
}

.comment-content h5 a {
    font-size: 20px;
}

.comment-metadata {
    display: inline-block;
    margin-left: 10px;
}

.comment-metadata span,
.comment-metadata a {
    color: #228B22;
}

.comment-reply a {
    font-size: 16px;
    font-weight: 700;
    font-family: 'lora', serif;
    text-transform: uppercase;
    color: #228B22;
}

.comments-area li ul.children {
    padding-left: 40px;
}

.comments-form input {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    color: #523906;
    border: 1px solid #f1efeb;
    margin-bottom: 20px;
    border-radius: 5px;
}

.comments-form textarea {
    width: 100%;
    height: 120px;
    padding: 20px;
    color: #523906;
    border: 1px solid #f1efeb;
    border-radius: 5px;
    margin-bottom: 20px;
}

.comments-form input:focus,
.comments-form textarea:focus {
    border: 1px solid #228B22;
}

.comments-form button {
    width: 270px;
    height: 60px;
    border-radius: 5px;
    display: inline-block;
}

.comments-form button svg {
    margin-left: 10px;
}


/**
*   25. Newsletter
*/

.newsletter-content-wrapper h2 {
    font-size: 36px;
    line-height: 46px;
    color: #fff;
    margin-bottom: 60px;
}

.newsletter-content-wrapper input {
    width: 50%;
    height: 60px;
    padding: 0 30px;
    border-radius: 50px;
    border: none;
    margin-right: 20px;
}


/**
*   26. Features
*/

.single-feature {
    padding: 47px 25px;
    transition: all 0.3s ease-out 0s;
}

.single-feature img {
    margin-bottom: 25px;
}

.single-feature h5 {
    margin-bottom: 25px;
}

.single-feature:hover {
    background-color: #523906;
}

.single-feature:hover h5,
.single-feature:hover p {
    color: #fff;
}


/**
*   27. Team
*/

.single-team-style-1-img {
    position: relative;
}

.single-team-style-1:hover {
    transition: .3s;
}

.single-team-style-1:hover .single-team-style-1-img:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #3e2c07;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    opacity: .75;
    visibility: visible;
    transition: .3s;
}

.single-team-style-1-img:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #3e2c07;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    overflow: hidden;
}

.single-team-style-1-img img {
    border-radius: 100%;
    width: 100%;
}

.single-team-style-1 h4 {
    margin-top: 35px;
    font-size: 20px;
}

.single-team-style-1 span {
    font-size: 18px;
    color: #228B22;
}

.team-social-style-1,
.team-social-style-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    width: 100%;
}

.team-social-style-1 a,
.team-social-style-2 a {
    display: inline-block;
    color: #fff;
    width: 36px;
    height: 36px;
    border: 2px solid #ddd;
    text-align: center;
    line-height: 30px;
    border-radius: 50px;
    font-size: 16px;
    margin: 0 2px;
}

.team-social-style-2 {
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
}

.team-social-style-1 a:hover,
.team-social-style-2 a:hover {
    background-color: #fff;
    color: #228B22;
    border-color: #fff;
}

.single-team-style-1:hover .team-social-style-1,
.single-team-style-2:hover .team-social-style-2 {
    visibility: visible;
    opacity: 1;
}

.single-team-style-2 {
    position: relative;
}

.single-team-content-style-2 {
    position: absolute;
    width: 100%;
    height: 130px;
    bottom: 0;
    background-color: #523906;
    padding: 40px 0;
}

.single-team-style-2-img img {
    border-radius: 250px 250px 0px 0px;
    width: 100%;
}

.single-team-style-2:hover .single-team-style-2-img:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #3e2c07;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 250px 250px 0px 0px;
    opacity: .75;
    visibility: visible;
    transition: .3s;
}

.single-team-style-2-img:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #3e2c07;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 250px 250px 0px 0px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    overflow: hidden;
}

.single-team-content-style-2 h4 {
    color: #fff;
    font-size: 20px;
}

.single-team-content-style-2 span {
    font-size: 18px;
    color: #228B22;
}

.team-border-bottom {
    position: relative;
}

.team-border-bottom:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -30px;
    right: -65px;
    width: 200px;
    height: 3px;
    background-color: #edebe6;
    text-align: center;
}


/**
*   28. Quote Form
*/

.quote-title h2:after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background-color: #228B22;
    margin-top: 25px;
}

.quote-title h2 {
    color: #fff;
    margin-bottom: 30px;
}

.quote-title p {
    color: #fff;
}

.book-quote-form,
.quote-area-form {
    background-color: #fff;
    padding: 50px 25px;
}

.quote-area-form h3 {
    font-size: 24px;
    margin-bottom: 40px;
}

.book-quote-form input,
.quote-area-form input,
.contact-form input {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    border: none;
    background-color: #f1f1f1;
    margin-bottom: 20px;
}

.book-quote-form textarea,
.quote-area-form textarea,
.contact-form textarea {
    width: 100%;
    height: 178px;
    background-color: #f1f1f1;
    border: none;
    padding: 20px;
    margin-bottom: 15px;
}

.book-quote-form button,
.quote-area-form button,
.contact-form button {
    width: 100%;
    border-radius: 0;
    font-weight: 400;
}


/**
*   29. Contact
*/

.contact-form .section-title span {
    margin-bottom: 20px;
}

.contact-form .l-btn {
    text-align: left;
}

.contact-form .l-btn svg {
    float: right;
}

.contact-form input,
.contact-form textarea {
    background-color: transparent;
    /* border: 1px solid #edebe6; */
    border: 1px solid #70707052;
}

.contact-box span {
    display: block;
    font-size: 18px;
    /* font-weight: 700; */
    color: #fff;
    margin-bottom: 30px;
}

.contact-box h3 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 25px;
}

.contact-box p {
    color: #fff;
}

.contact-box ul li svg {
    float: left;
    color: #228B22;
    line-height: 26px;
    margin: 5px 15px 5px 0;
}

.contact-box ul li span {
    display: block;
    overflow: hidden;
    font-size: 18px;
    font-weight: 600;
    font-family: 'lora', serif;
    line-height: 26px;
    color: #fff;
    margin-bottom: 35px;
}

.contact-box ul li span a {
    color: #fff;
}

.social-btn-area .l-btn {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;
    padding: 0;
    width: 200px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.contact-testimonial h2 {
    font-size: 30px;
    margin-bottom: 25px;
}

.single-testimonial-style-3-navigator img {
    width: 65px;
    height: 65px;
    border-radius: 50px;
    float: left;
    margin-right: 20px;
    padding: 3px;
}

.single-testimonial-style-3-navigator h5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.single-testimonial-style-3-navigator {
    opacity: .4;
}

.slick-active.slick-current .single-testimonial-style-3-navigator {
    opacity: 1;
}

.slick-active.slick-current .single-testimonial-style-3-navigator img {
    width: 65px;
    height: 65px;
    border: 1px solid #228B22;
    padding: 3px;
}

.testimonial-style-3-navigator .slick-prev,
.testimonial-style-3-navigator .slick-next {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50px;
    font-size: 14px;
    background-color: #523906;
    color: #fff;
    position: absolute;
    bottom: 15px;
    z-index: 99;
}

.testimonial-style-3-navigator .slick-prev {
    right: 40px;
}

.testimonial-style-3-navigator .slick-next {
    right: 0;
}

.testimonial-style-3-navigator .slick-arrow:hover {
    background-color: #228B22;
    color: #fff;
}

.map-area iframe {
    width: 100%;
    height: 450px;
    margin-bottom: 0;
}

.seed-text {
    font-size: 17px;
    color: black;
}




/* work */

.work-with-div {

    /* background-color: #106f04ea; */
    /* background-color: #307b4e; */
    /* background-color: #228b22d7; */
    background-color: #bed6c5;
    /* background-color: #aaccb8; */
    /* background-color: #99c1b2; */
    padding-bottom: 5rem;
}

.work-with-div p,
.home-trusted-page p {
    color: #ffffff;
    width: 70%;
    padding-top: 1rem;
    margin: auto;
    text-align: center;
}

.work-with {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 40px;
    column-gap: 25px;
    padding: 20px 50px;
}


.buyerbg {
    background: url("https://www.owlguru.com/wp-content/uploads/wpallimport/files/buyers-and-purchasing-agents-farm-products/__(1).jpg");
    background-size: cover;
}

.Machinarybg {
    background: url("https://assets.isu.pub/document-structure/230311101920-839c5223f11455a9a587ac243d479b00/v1/366fb4efb4073cce378027ca99d659b2.jpeg");
    background-size: cover;
}



.category-box {
    border-radius: 15px;
    text-align: center;
    color: white;
    /* Optional: Text color */
    min-height: 190px;
}

.category-blur-box {
    -webkit-backdrop-filter: brightness(55%);
    backdrop-filter: brightness(55%);
    height: 100%;
    width: 100%;
    border-radius: 15px;
    padding: 30px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-box:hover {
    background-color: rgba(167, 167, 167, 0.811);
    box-shadow: 0px 0px 10px 0px #000000;
    transition: all 0.4s ease;
}


.work-with div h3 {
    color: white;
}

.flex-center-class {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.core-values-div-2 {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    gap: 20px;
    padding: 20px 10px;
}

.core-values-image {
    height: 380px;
    width: 375px;
    border-radius: 50%;
    border: 2px dashed black;
    padding: 5px;
}

.why-fmc-div {
    background-color: #f4f9ff;
}

.why-fmc-div-1 {
    display: grid;
    grid-template-columns: 0.55fr 1fr;
    width: 75%;
    margin: auto;
}

.why-fmc-div-2 {
    display: grid;
    grid-template-columns: 1fr 0.55fr;
    width: 75%;
    margin: auto;
    padding-top: 80px;
}

.why-fmc-div-1-img {
    height: 275px;
    width: 275px;
    border-radius: 50%;
    border: 2px dashed black;
    padding: 5px;
}

.why-fmc-div-1-span {
    max-width: 630px;
}

.why-fmc-div-2-span {
    padding-left: 70px;
}

.vision-div {
    background-color: #ffffff;
}

.vision-div-1 {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-bottom: 80px;
}

.vision-div-1_1-img1 {
    height: 240px;
    width: 240px;
    border-radius: 150px;
    filter: grayscale(100%);
}

.vision-div-1_1 {
    border-radius: 150px 0 0 150px;
    display: flex;
    gap: 25px;
    padding-right: 10px;
    min-width: 550px;
    width: 52%;
    background-color: #f2f2f2;
    margin: 0px 0px 0px auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 20px 3px, rgba(0, 0, 0, 0.3) 0px -1px 13px 3px;
}

.vision-div-1_1-text {
    margin: auto 0 auto 0;
    padding: 8px 0px 8px 0px;
}




.vision-div-1_2-img1 {
    height: 240px;
    width: 240px;
    border-radius: 150px;
}

.vision-div-1_2 {
    border-radius: 0 150px 150px 0;
    display: flex;
    background-color: #f2f2f2;
    gap: 25px;
    padding-left: 18px;
    min-width: 550px;
    width: 52%;
    margin: 0px auto 0px 0px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 20px 3px, rgba(0, 0, 0, 0.3) 0px -1px 13px 3px;
}

.vision-div-1_2-text {
    padding: 8px 0px 8px 0px;
    margin: auto 0 auto 0;
}

.mission-div {
    background-color: rgba(223, 223, 223, 0.183);
}

.mission-div-1 {
    background-color: #bed6c5;
}

.mission-div-1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
}

.mission-div-1_1 {
    padding: 29px 5px;
    display: flex;
    gap: 18px;
    flex-direction: column;
    align-items: center;
}

.mission-div-2 {
    padding: 30px 29px 60px 29px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    justify-items: center;
}

.mission-div-2_1,
.mission-div-2_2 {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.market-div-1 {
    width: 90%;
    margin: auto;
    padding: 35px 35px 25px 35px;
    background-color: #bed6c5;
    border-radius: 10px;
}

.market-div-1 {
    display: grid;
    grid-template-rows: 0.8fr 0.5fr;
    grid-template-columns: 1fr;
    gap: 30px;
}

.market-div-1_1 {
    display: grid;
    grid-template-columns: 1fr 0.8fr 1fr;
    gap: 20px;

}

.market-div-1_1 {
    padding: 30px 40px 30px 40px;
}

.market-div-1_2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 19px;
    margin-top: 30px;
}

.market-div-1_2-child h4 {
    text-align: center;
}

.market-div-1_2-child-img {
    height: 40%;
    width: 20%;
}

.market-div-1_2-child {
    min-height: 170px;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 10px;
    background-color: rgba(238, 238, 238, 0.575);
    align-items: center;
    justify-content: center;
}




.topbutton-div {
    /* width: 416px; */
    margin: auto;
    /* background: linear-gradient(rgb(130, 130, 166), rgb(57, 57, 201)); */
    background: linear-gradient(#008c2f, #008c2f);
    padding-bottom: 15px;
    border-radius: 7px 7px 0px 0px;
}

.topbuttoncity {
    width: 415px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    padding: 10px 2px;
}

.topbuttoncity button {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    padding: 3px;
}

.input-div {
    width: 100%;
    padding: 4px 8px;
}

.input-div input {
    background-color: white;
    height: 30px;
    border-radius: 5px;
    width: 190px;
    font-size: 1rem;
    color: rgb(43, 43, 43);
    letter-spacing: 0.5px;
    border: none;
    margin-left: 50px;
}

.input-div input:focus {
    background-color: white;
}

.input-div i {
    margin-left: 18px;
    cursor: pointer;
}

.input-div {
    display: flex;
    align-items: center;
}

.input-div i:hover {
    transform: scale(1.3);
    transition: all 0.3s ease;
}

.tempanddetail-div {
    padding-top: 10px;
    text-align: center;
}

.tempanddetail-div-1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 20px;
}

.tempanddetail-div-1_3 {
    display: flex;
    flex-direction: column;

}

.tempanddetail-div-2 {
    padding-top: 29px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 3px;
    column-gap: 15px;
}

.forecast-div {
    margin-top: 20px;
}

.weathermodel .modal-body {
    padding: 0 !important;
}

.why_fmc-div-1 {
    display: flex;
    justify-content: space-between;
    padding: 5px 130px;
    /* max-width: 85%; */
    margin: auto;
}

.why_fmc-div-1_1 p {
    text-align: center;
    padding-top: 15px;
    font-size: 1rem;
    color: black;
}

/* .our-services-div-1_2 {
    -webkit-backdrop-filter: brightness(40%);
    backdrop-filter: brightness(40%);
    height: 100%;
    width: 100%;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */

.our-services-div-1 {
    width: 87%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 22px;
    row-gap: 25px;
    margin: auto;

}

.our-services-div-1_1:hover {
    transform: scale(1.01);
    transition: all 0.4s ease;
}

.our-services-div-1_1 {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    padding: 25px 25px 25px 35px;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    border-radius: 12px;
    background-color: rgb(247, 247, 247);
}

.our-services-div {
    background: linear-gradient(180deg, #56B18324 19%, #a055c214 91%);
}

/* .our-services-div-1_1 {
    border-radius: 8px;
    min-height: 240px;
    border: 1px solid black;
} */

.res-imge-class img {
    height: 100%;
    width: 100%;
}

.b-div-2 {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
    justify-content: center;
}


.outer-border {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    height: 210px;
    border: 12px solid #3b6b8d;
    border-radius: 50%;
    padding: 10px;
    position: relative;
    background-color: white;
    /* Optional: Background color */
}

.inner-border {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px dotted rgb(255, 255, 255);
    border-radius: 50%;
    padding: 98px;
    box-sizing: border-box;
}

.trophy-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.b-div-2_1 {
    display: flex;
    gap: 40px;
}

.b-div-2_1_3 {
    height: 200px;
    width: 765px;
    border-left: 12px solid #69c790;
    border-right: 2px dashed #69c790;
    border-top: 2px dashed #69c790;
    border-bottom: 2px dashed #69c790;
    border-radius: 20% 20% 20% 20%;
    background-color: #f9f9f9;
    padding: 35px 10px 10px 30px;
}

.b-div-2_1_2 {
    height: 200px;
    width: 730px;
    border-left: 12px solid #10bebf;
    border-right: 2px dashed #10bebf;
    border-top: 2px dashed #10bebf;
    border-bottom: 2px dashed #10bebf;
    border-radius: 20% 20% 20% 20%;
    background-color: #f9f9f9;
    padding: 35px 10px 10px 30px;
}
.why_fmc-div{
    width: 100% !important;
}

.b-div-2_1_1 {
    height: 200px;
    width: 730px;
    border-left: 12px solid #3b6b8d;
    border-right: 2px dashed #3b6b8d;
    border-top: 2px dashed #3b6b8d;
    border-bottom: 2px dashed #3b6b8d;
    border-radius: 20% 20% 20% 20%;
    background-color: #f9f9f9;
    padding: 35px 10px 10px 30px;
}

.b-div-2_1_1 ul li,
.b-div-2_1_2 ul li,
.b-div-2_1_3 ul li {
    color: rgb(32, 32, 32);
    list-style-type: disc;
    margin-left: 17px;
}


.b-div-2_1_3 h3 {
    border-left: 7px solid #69c790;
    padding-left: 8px;
    color: black;
}

.b-div-2_1_2 h3 {
    border-left: 7px solid #10bebf;
    padding-left: 8px;
    color: black;
}

.b-div-2_1_1 h3 {
    border-left: 7px solid #3b6b8d;
    padding-left: 8px;
    color: black;
}

.header-btn Button,
.header-btn a {
    border-radius: 10px;
}

.header-btn Button:hover {
    background-color: #fc9604 !important;
}

.our-services-div-2 {
    height: 300px;
    margin-bottom: 55px;
}

.our-services-div-2_1 h1 {
    color: white;
    font-weight: 500;
}

.our-services-div-2_1 p {
    color: rgb(225, 225, 225);
    width: 70%;
    text-align: center;
    font-size: 1.1rem;
}



.our-services-div-2_1 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    height: 100%;
    width: 100%;
    -webkit-backdrop-filter: brightness(31%);
    backdrop-filter: brightness(40%);
    /* background-color: #386e8494; */
    /* background-color: #7599a7dc; */
    background-color: #52a4626e;
}


.category-div {
    max-width: 100vw !important;
    overflow: hidden !important;
    background: url('../images/BGIMAGE1 (5).jpg');
    background-size: 110px 160px;
    background-repeat: repeat;
    background-position: top left;
}



.category-div-1 {
    padding: 70px 30px 100px 30px;
    /* -webkit-backdrop-filter: blur(3px) opacity(510000%);
    backdrop-filter: blur(3px) opacity(510000%); */
}

.category-div .swiper,
.customer-review .swiper {
    padding: 20px 10px 20px 10px !important;
}

.card-div-1 {
    padding: 20px 30px;
    /* height: 350px;
    width: 250px; */
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    position: relative;
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 7px;
    border-radius: 8px 10px 8px 8px;
    overflow: hidden;

}

.space1 {
    position: absolute;
    top: -4px;
    right: -3px;
    height: 63px;
    width: 63px;
    box-shadow: 5px 0 5px -5px rgb(0 0 0 / 37%)inset, 0 -5px 5px -5px rgb(0 0 0 / 37%) inset;
    background-color: rgb(255, 255, 255);
    border-radius: 0 10px 0px 24px;
}




.overflow {
    width: 210px;
    height: 210px;
    overflow: hidden;
    border-radius: 50%;
    margin-top: 20px;
}

.card-div:hover {
    cursor: pointer;
}

.card-div:hover .overflow img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    border: 4px solid green;
}

.overflow img {
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    width: 210px;
    height: 210px;
    border: 4px solid green;
    border-radius: 50%;
}


.card-div-1 h2 {
    font-size: 1.3rem;
    text-align: center;
    font-weight: 550;
}

.corner-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 45px;
    height: 45px;
    background-color: #ff9702;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.corner-icon::before {
    content: '↗';
    color: white;
    font-size: 24px;
}


















.market-div-2 {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr; */
    justify-items: center;
    padding: 20px 40px 20px 40px;
}

.market-div-2_4 {
    background-color: #ff9702;
    background-color: #94cea1;
    padding: 25px;
    border-radius: 50%;
}

.market-div-2_1 {
    display: flex !important;
    flex-direction: column;
    align-content: center !important;
    align-items: center !important;
    gap: 40px;
    /* padding-top: 90px; */
    /* padding-bottom: 70px; */
    justify-content: center;
    max-width: 450px;
}

@media only screen and (max-width: 1199px) and (min-width:700px) {
    .market-div-2_1 {
        display: flex !important;
        flex-direction: row;
        gap: 40px;
        padding-top: 90px;
        padding-bottom: 70px;
        justify-content: center;
        max-width: 100% !important;
    }
}

.market-div-2_2 {
    display: flex;
    gap: 30px;
    align-items: center;
}

.market-div-2_5 {
    display: flex;
    flex-direction: column;
}

.header-bottom {
    /* position: sticky; */
    position: relative;
    /* top: 0; */
    /* background-color: white; */
    background-color: transparent;
    /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); */
    z-index: 100;
}

.HeaderBottomThree-div {
    background-color: white;
    padding: 10px 10px 0px 10px;
}

.image-header {
    position: relative;
}

.image-header img {
    height: 110vh;
    width: 100%;
    border-radius: 30px;
}

@media only screen and (max-width: 1047px) and (min-width:700px) {
    .image-header img {
        height: 100vh;
        width: 100%;
        border-radius: 30px;
    }

    .details-homepage {
        top: 180px !important;
        max-width: 90% !important;
        left: 50px !important;
    }
}

@media only screen and (max-width: 699px) and (min-width:619px) {
    .image-header img {
        height: 70vh;
        width: 100%;
        border-radius: 30px;
    }

    .details-homepage {
        top: 130px !important;
        max-width: 90% !important;
        left: 50px !important;
    }

    .slider-description {
        font-size: 15px !important;
    }

    .slider-description-head {
        font-size: 25px !important;
    }
}

@media only screen and (max-width: 618px) and (min-width:200px) {
    .image-header img {
        height: 40vh;
        width: 100%;
        border-radius: 30px;
    }

    .details-homepage {
        top: 90px !important;
        max-width: 90% !important;
        left: 30px !important;
    }

    .slider-description {
        font-size: 13px !important;
    }

    .slider-description-head {
        font-size: 20px !important;
    }
}

.main-header-div {
    position: absolute !important;
    top: 10px;
    left: 15px;
    display: flex;
    align-items: center;
    width: 69%;
    justify-content: space-around;
}

.header-btn {
    padding: 10px;
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: white;
    border-radius: 0 0 0 20px;
}


.pbmit-sticky-corner {
    width: 30px;
    height: 30px;
    position: absolute;
    transform: rotate(270deg);
    background-color: transparent;
}

.pbmit-top-left-corner {
    top: 0;
    left: -30px;
    display: none;
}

.pbmit-top-right-corner {
    bottom: -30px;
    right: 0;
    display: none;
}

.main-header-div .main-menu ul li a {
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    display: block;
    padding: 32px 0 25px 0;
}

.main-header-div .main-menu ul li:hover>a,
.main-header-div .main-menu ul li .submenu li:hover>a {
    color: #c0e5c0;
}

.details-homepage {
    position: absolute;
    top: 240px;
    left: 105px;
    max-width: 50%;
}

.details-homepage p {
    font-size: 1.17rem;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 500;
    color: #454545;
}

.customer-review {
    padding: 72px 32px 58px 32px;
    background-color: #f8f7f0;
}

.customer-review-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer-review-1 h1 {
    font-weight: 580;
}

.customer-review-1 button {
    padding: 10px 15px;
    border: 2px solid grey;
    border-radius: 5px;
    background-color: transparent;
}

.customer-review-1 button:hover {
    background-color: #404a3d;
    color: white;
}

.card-div5 {
    background-color: white;
    /* height: 400px;
    width: 280px; */
    /* border: 1px solid red; */
    position: relative;
    padding-top: 40px;
    border-radius: 8px;
    padding-bottom: 30px;
}

.card-div6 {
    padding: 15px;
}

.card-div6 p {
    font-size: 1.2rem;
}

.text-div {
    padding-top: 20px;
}

.corner1 {
    position: absolute;
    top: 0;
    right: 0;
    /* border: 1px solid red; */
    border-radius: 0 0 0 30px;
    height: 60px;
    width: 60px;
    background-color: rgb(248 247 240);
}

.card-div5 .pbmit-sticky-corner svg path {
    fill: rgb(248 247 240);
}

.detail-div {
    margin-top: 35px;
    display: flex;
    align-items: center;
    gap: 18px;
}

.dots {
    background-color: #eddd5e;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    position: relative;
    left: 9px;
    top: 2px;
}

.benefits-divv {
    /* background: url('../images/BGIMAGE1 (5).jpg');
    background-size: 110px 160px;
    background-repeat: repeat;
    background-position: top left; */
    background-color: #f8f7f0;
    padding-top: 60px;
    padding-bottom: 90px;
}

.b-div-3 {
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr 1fr; */
    width: 92%;
    gap: 30px;
    margin: auto;
}

.b-div-3_1:hover {
    background-color: white;
    border: 1px solid white;
    transition: all 0.2s ease;
}

.b-div-3_1 {
    background-color: transparent;
    border: 1px solid rgb(200, 200, 200);
    border-radius: 13px;
    padding: 28px;
    min-height: 380px;
}

.b-div-3_1 p {
    font-size: 1.15rem;
}

.b-div-3_2 h3 {
    font-weight: 590;
    /* max-width: 140px; */
}

.b-div-3_2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(200, 200, 200);
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.b-div-3_1 ul li {
    color: rgb(32, 32, 32);
    list-style-type: disc;
    margin-left: 17px;
    padding-bottom: 10px;
}



@media (max-width:1287px) {
    .market-div-1_2-child-img {
        height: 40%;
        width: 25%;
    }

    .market-div-1_1 {
        padding: 0;
    }

    .vision-div-1_1,
    .vision-div-1_2 {
        width: 64%;
    }

    .work-with {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width:1024px) {

    .b-div-2_1_2,
    .b-div-2_1_1,
    .b-div-2_1_3 {
        width: 630px;
    }

    .our-services-div-1 {
        width: 95%;
    }

    .market-div-1_2-child h4 {
        font-size: 1rem;
    }

    .market-div-1_2-child {
        min-height: 120px;
    }

    .market-div-1_2-child-img {
        height: 45%;
        width: 25%;
    }

    .vision-div-1_1,
    .vision-div-1_2 {
        width: 80%;
    }

    .why-fmc-div-1,
    .why-fmc-div-2 {
        width: 95%;
        gap: 15px;
    }

    .why-fmc-div-2-span {
        padding-left: 40px;
    }

}



@media (max-width:954px) {

    .core-values-div-2 {
        grid-template-columns: 1fr;
    }

    .core-values-div-2_2 {
        padding: 10px 70px;
    }
}



@media (max-width:885px) {
    .b-div-2_1 {
        gap: 10px;
    }

    .b-div-2_1_2,
    .b-div-2_1_1,
    .b-div-2_1_3 {
        height: 200px;
        width: 550px;
        padding: 20px 10px 10px 30px;
    }

    .our-services-div p {
        width: 89%;
    }

    .work-with {
        grid-template-columns: 1fr 1fr;
    }

    .our-services-div-1 {
        grid-template-columns: 1fr;
        width: 85%;
    }
}


@media (max-width:768px) {
    .logo {
        padding: 0 !important;
    }

    .why_fmc-div-1 {
        padding: 5px 5px;
        /* max-width: 89%; */
        gap: 10px;
        margin: auto;
    }

    .market-div-1 {
        padding: 20px;
    }

    .market-div-1_2-child h4 {
        font-size: 0.7rem;
    }

    .market-div-1_2-child {
        min-height: 60px;
        padding: 10px;
    }

    .market-div-1_2-child-img {
        height: 42%;
        width: 25%;
    }

    .mission-div-2_2 {
        margin-top: 40px;
    }

    .mission-div-2 {
        grid-template-columns: 1fr;
    }

    .core-values-div-2_2 {
        padding: 10px 40px;
    }

    .vision-div-1_1,
    .vision-div-1_2 {
        width: 99%;
    }

    .vision-div-1 {
        gap: 50px;
    }
}

@media (max-width:760px) {
    .b-div-2 {
        gap: 70px;
    }

    .b-div-2_1 {
        flex-direction: column;
        gap: 20px;
    }
}



@media (max-width:687px) {
    .mission-div-1 {
        grid-template-columns: 1fr 1fr;
    }

    .vision-div-1_1-img1,
    .vision-div-1_2-img1 {
        height: 200px;
        width: 200px;
        border-radius: 150px;
    }


    .why-fmc-div-1,
    .why-fmc-div-2 {
        grid-template-columns: 1fr;
        gap: 38px;
    }

    .why-fmc-div-2-span {
        grid-row-start: 2;
    }

    .why-fmc-div-1-span {
        padding: 2px 20px;
    }
}

@media (max-width:620px) {

    .b-div-2_1_2,
    .b-div-2_1_1 {
        height: 250px;
        width: 485px;
        padding: 25px 10px 10px 25px;
    }

    .b-div-2_1_3 {
        height: 210px;
        width: 485px;
        padding: 25px 10px 10px 25px;
    }

    .res-imge-class img {
        height: 50%;
        width: 380px !important;
    }

    .work-with {
        grid-template-columns: 1fr;
        padding: 6px 23px
    }

    .why_fmc-div-1_1 p {
        font-size: 0.8rem;
    }
    .project-area{
        width: 100%;
    }

    .our-services-div-1 {
        width: 93%;
    }

    .market-div-1_2-child-img {
        height: 48%;
        width: 30%;
    }

    .market-div-1 {
        padding: 10px;
    }

    .market-div-1_2-child h4 {
        font-size: 0.65rem;
    }

    .market-div-1_2-child {
        min-height: 60px;
        padding: 8px;
    }

    .vision-div-1_1-img1,
    .vision-div-1_2-img1 {
        display: none;
    }

    .vision-div-1_1-text {
        padding: 25px 10px 25px 65px;
    }

    .vision-div-1_2-text {
        padding: 25px 30px 25px 20px;
    }

}

@media (max-width:496px) {

    .b-div-2_1_2,
    .b-div-2_1_1 {
        height: 250px;
        width: 405px;
        padding: 25px 10px 10px 25px;
    }

    .b-div-2_1_3 {
        height: 230px;
        width: 405px;
        padding: 25px 10px 10px 25px;
    }

    .work-with {
        gap: 20px;
        padding: 6px 23px;
    }

    .res-imge-class img {
        height: 50%;
        width: 340px !important;
    }

    .why_fmc-div-1 {
        padding: 5px 5px;
        /* max-width: 95%; */
        gap: 10px;
        margin: auto;
    }

    .market-div-1_2-child-img {
        height: 68%;
        width: 40%;
    }

    .market-div-1 {
        gap: 2px;
    }

    .why-fmc-div-1-span {
        padding: 2px 10px;
    }

    .work-with-div p,
    .home-trusted-page p {
        width: 60%;
    }

    .core-values-div-2_2 {
        padding: 10px 20px;
    }

}

/* customStyles.css */
.custom-pagination {
    display: flex;
    z-index: 999;
    justify-content: center;
    margin-top: 10px;
  }
  
  .custom-bullet {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .custom-bullet-active {
    background-color: #008c2f;
    border-radius: 25%;
    width: 25px;
  }
  
  .remove-scroll {
    -ms-overflow-style: none;
  
  }
  
  .remove-scroll::-webkit-scrollbar {
    width: 0;
    height: 0;
  }